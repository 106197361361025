<template>
  <div v-if="!isLoading">
    <SettlementPaymentTutorial title="Pembayaran"/>
  </div>
</template>

<script>
import SettlementPaymentTutorial from '@/components/Settlement/SettlementPaymentTutorial.vue'

export default {
  name: 'how-to-pay-bill',
  components: {
    SettlementPaymentTutorial,
  },
  data: function () {
    return {
      isLoading: false,
      isFullCoin: false,
      loyaltyUsage: 0,
      subTotal: 0,
    }
  },
  methods: {
    getOrderDetail() {
      this.isLoading = true
      this.$store.dispatch('billing/GETDETAILSETTLEMENT', {
        payment_id: this.$route.params.payment_id,
        channel_id: this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          this.isFullCoin = Boolean(data.gross_total === 0)
          this.loyaltyUsage = data.loyalty_usage ?? 0
          this.subTotal = data.sub_total ?? 0
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.$notification.error({
            message: 'Gagal memuat data',
            description: err.response?.data?.message || err.message,
          })
        })
    },
  },
  mounted() {
    // this.getOrderDetail()
  },
}
</script>

<style lang="scss">
  .btn-bottom {
    background: #265c9b;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    border: none;
    width: 275px;
    padding: 10px;
  }
</style>
