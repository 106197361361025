<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 426.667 426.667"
    style="enable-background: new 0 0 426.667 426.667"
    xml:space="preserve"
  >
    <path
      d="M383.168,113.227c-0.537-1.284-1.319-2.451-2.304-3.435L274.197,3.125c-2.002-1.991-4.707-3.114-7.531-3.125H96c-5.891,0-10.667,4.776-10.667,10.667v32h-32c-5.891,0-10.667,4.776-10.667,10.667V416c0,5.891,4.776,10.667,10.667,10.667h277.333c5.891,0,10.667-4.776,10.667-10.667v-32h32c5.891,0,10.667-4.776,10.667-10.667v-256C383.997,115.923,383.714,114.527,383.168,113.227z M277.333,36.416l70.251,70.251h-70.251V36.416z M320,405.333H64V64h21.333v309.333C85.333,379.224,90.109,384,96,384h224V405.333z M362.667,362.667h-256V21.333H256v96c0,5.891,4.776,10.667,10.667,10.667h96V362.667z"
      fill="#FF685E"
    />
  </svg>
</template>

<script>
export default {
  name: 'CopyIcon',
}
</script>
